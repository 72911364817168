import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import TextSizeSelector from "components/TextSizeSelector";
import ColorInput from "components/ColorInput";
import ScrollSpeedSelector from "components/ScrollSpeedSelector";
import Button from "components/Button";
import { useUpdateDisplayTickerTape } from "../graphql/useTickerTapes";
import { useParams } from "react-router-dom";
import debounce from "lodash/debounce";
import classNames from "classnames";

const TickerTapeDisplayMode = ({ displayScrollSpeed, displayTextSize, displayStyles, theme }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { updateDisplayTickerTape } = useUpdateDisplayTickerTape();

    const [parsedDisplayStyles, setParsedDisplayStyles] = useState(() => {
        try {
            return typeof displayStyles === "string" ? JSON.parse(displayStyles) : displayStyles || {};
        } catch (error) {
            console.error("Error parsing displayStyles:", error);
            return {};
        }
    });

    useEffect(() => {
        try {
            const newStyles = typeof displayStyles === "string" ? JSON.parse(displayStyles) : displayStyles || {};
            setParsedDisplayStyles(newStyles);
        } catch (error) {
            console.error("Error parsing displayStyles:", error);
            setParsedDisplayStyles({});
        }
    }, [displayStyles]);

    const bgTheme = theme?.defaultBackground;
    const fgTheme = theme?.defaultForeground;

    const debouncedUpdateTickerTape = useCallback(
        debounce((variables) => {
            updateDisplayTickerTape({ variables });
        }, 1500),
        [updateDisplayTickerTape]
    );

    const updateDisplay = useCallback(
        (variables) => {
            debouncedUpdateTickerTape({ id, ...variables });
        },
        [id, debouncedUpdateTickerTape]
    );

    const handleStyleChange = (key, value) => {
        setParsedDisplayStyles((prev) => {
            const updatedStyles = { ...prev, [key]: value };
            updateDisplay({ displayStyles: updatedStyles });
            return updatedStyles;
        });
    };

    const resetThemeColors = () => {
        setParsedDisplayStyles((prev) => {
            const updatedStyles = {
                ...prev,
                bgColor: bgTheme,
                fgColor: fgTheme,
            };
            updateDisplay({ displayStyles: updatedStyles });
            return updatedStyles;
        });
    };

    const handleTextSizeChange = (size) => {
        updateDisplay({ displayTextSize: size });
    };

    const handleScrollSpeedChange = (speed) => {
        updateDisplay({ displayScrollSpeed: speed });
    };

    // Limpiar el debounce al desmontar
    useEffect(() => {
        return () => {
            debouncedUpdateTickerTape.cancel();
        };
    }, [debouncedUpdateTickerTape]);

    return (
        <div className="w-full flex">
            <div className="w-full rounded bg-white p-6">
                <div className="w-full flex">
                    <span className="text-2xl font-bold">{t("display-mode")}</span>
                    <Button className="ml-4" id="display-mode-position-preview">
                        <span className="font-bold text-zafiro-600">{t("preview")}</span>
                    </Button>
                </div>

                <div className="border-b border-gray-300">
                    <span>{t("set-up-the-ticker-tape-display-mode")}</span>
                </div>

                <div className="w-full flex flex-col items-center justify-center">
                    <span className="block w-full font-bold text-left mt-4">{t("text-size")}</span>
                    <TextSizeSelector value={displayTextSize} onChange={handleTextSizeChange} />
                </div>

                <div>
                    <span className="block w-full font-bold text-left mt-3">{t("scroll-speed")}</span>
                    <div className="mt-5">
                        <ScrollSpeedSelector value={displayScrollSpeed} onChange={handleScrollSpeedChange} />
                    </div>
                </div>

                <div className="w-full">
                    <div className="flex mb-4">
                        <span className="block w-full font-bold text-left mt-4">{t("colours")}</span>
                        <Button className="ml-4" onClick={resetThemeColors} id="display-mode-colours-reset">
                            <div className="whitespace-no-wrap font-bold text-zafiro-600">
                                {t("use-appareance-colours")}
                            </div>
                        </Button>
                    </div>

                    <div className="w-4/5">
                        <div className="flex items-center w-full justify-between pr-32 mt-3">
                            <p
                                className={classNames({
                                    "text-gray-800 italic": parsedDisplayStyles?.bgColor !== bgTheme,
                                })}
                            >
                                {t("background") + (parsedDisplayStyles?.bgColor !== bgTheme ? "*" : "")}
                            </p>
                            <ColorInput
                                value={parsedDisplayStyles?.bgColor}
                                id="color-input-background"
                                onChange={(color) => handleStyleChange("bgColor", color)}
                            />
                        </div>
                        <div className="flex items-center w-full justify-between pr-32 mt-3">
                            <p
                                className={classNames({
                                    "text-gray-800 italic": parsedDisplayStyles?.fgColor !== fgTheme,
                                })}
                            >
                                {t("text") + (parsedDisplayStyles?.fgColor !== fgTheme ? "*" : "")}
                            </p>
                            <ColorInput
                                value={parsedDisplayStyles?.fgColor}
                                id="color-input-text"
                                onChange={(color) => handleStyleChange("fgColor", color)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TickerTapeDisplayMode;
