import React, { useEffect, useState } from "react";
import Translations from "components/Translations";
import Icon from "components/Icon";
import { useAuth } from "hooks/Session/auth";
import { predefinedTags } from "hooks/Utils/Utils";
import { useUpdateTickerTapes } from "../graphql/useTickerTapes";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loading from "components/Loading";

export const TickerTapesContent = ({ getData, translations: trans, loading }) => {
    const { t } = useTranslation();
    const { languages: projectLangs } = useAuth();

    const { updateTickerTape } = useUpdateTickerTapes({
        onCompleted: () => {
            getData();
        },
    });
    const { id } = useParams();
    const [translations, setTranslations] = useState(false);

    useEffect(() => {
        setTranslations(
            Object.entries(JSON.parse(trans || "{}")).map(([key, value]) => {
                return { languageCode: key, name: value };
            })
        );
    }, [trans]);

    useEffect(() => {
        if (translations) {
            updateTickerTape({
                variables: {
                    id,
                    content: translations.map((trans) => ({ lang: trans.languageCode, text: trans.name })),
                },
            });
        }
    }, [translations]);

    return (
        <div className="w-full bg-white rounded p-6">
            <div className=" border-b border-gray-300 mb-2">
                <h2 className=" font-bold text-2xl">{t("content")}</h2>
                <p>{t("add-and-translate-the-content")}</p>
            </div>
            <div className=" flex gap-6 my-4 justify-center items-center">
                <Icon type={"warning"}></Icon>

                <ul className=" list-disc">
                    <li>{t("in-rooms-the-message-will-be-displayed-in-the-language-of-the-guest")}</li>
                    <li>{t("in-common-areas-the-message-will-be-displayed-in-all-languages")}</li>
                </ul>
            </div>
            {!loading && projectLangs && translations ? (
                <Translations
                    id="content-translations"
                    maxHeight="10rem"
                    languages={projectLangs?.map((language) => language.languageRef)}
                    translations={translations}
                    defaultLang={projectLangs?.find((language) => language.isDefault)?.languageRef}
                    tags={[
                        {
                            label: t("predefined-tags"),
                            options: predefinedTags(t).map((tag) => ({
                                ...tag,
                                value: tag.value.replace(/\{|\}|\./g, ""),
                            })),
                        },
                    ]}
                    multiline={true}
                    placeholder="Enter translation"
                    onChange={(translations) => {
                        setTranslations(translations);
                    }}
                />
            ) : (
                <Loading></Loading>
            )}
        </div>
    );
};
