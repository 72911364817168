import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoading } from "components/Loading";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import Table from "components/ZafiroTable";
import UseSectionHeader from "components/useSectionHeader";
import {
    useDeleteTickerTapes,
    useListTickerTapes,
    useUpdateTickerTapes,
    useSetAvailabilityTickerTape,
    useDuplicateTickerTape,
} from "../graphql/useTickerTapes";
import {
    stateFilterOptions,
    FILTER_VALUES,
    INITIAL_BATCH_ACTIONS,
    HEADERS,
    COLUMNS_TICKER_TAPES,
    TICKER_TAPE_TYPE,
} from "../constants";
import { NameColumn, FiltersColumn, StatusColumn } from "../TableColumns";
import { ScheduledDateColumn, getCurrentTime } from "../utils";
import { UseModalAddTickerTape } from "../modals/tickerTapes/useModalAddTickerTape";
import { UseModalDelete } from "../modals/useModalDelete";
import { UseModalChangeName } from "../modals/useModalChangeName";
import { UseModalChangeAvailability } from "../modals/useModalChangeAvailability";
import { UseModalDuplicate } from "../modals/useModalDuplicate";

const MapRows = (props) => {
    const {
        t,
        data,
        modals,
        updateTickerTape,
        deleteTickerTapes,
        navigate,
        setAsAvailable,
        setAsUnavailable,
        duplicateTickerTape,
        loadingDuplicateTickerTape,
    } = props;
    const {
        name,
        destinationInfo,
        filtersNum,
        scheduleStart,
        scheduleEnd,
        id,
        content,
        available,
        ongoing,
        timeRanges,
    } = data;
    const {
        openModalFilters,
        openModalChangeName,
        openModalDeleteTickerTape,
        openModalChangeAvailabity,
        openModalDuplicateTickerTape,
    } = modals;

    const lastShowRange = timeRanges?.sort(function (a, b) {
        return b?.startTime?.localeCompare(a.startTime);
    })?.[0]?.endTime;
    const lastDate = timeRanges
        ? new window.ZDate(`${scheduleEnd}T${lastShowRange}`)
        : new window.ZDate(`${scheduleEnd}`);
    const expired = scheduleEnd && new window.ZDate() > lastDate;
    const numberOfFilters = filtersNum;
    const couldBeAvailable =
        Object.entries(JSON.parse(content || "{}"))?.length > 0 && scheduleStart && numberOfFilters > 0;

    return {
        id: id,
        data: data,
        expired,
        onGoing: ongoing,
        name: NameColumn({ t, name, id, navigate, section: "tickertapes" }),
        schedule_end: ScheduledDateColumn({ scheduleStart, scheduleEnd }),
        filters_num: FiltersColumn({
            t,
            name,
            filters: destinationInfo,
            numberOfFilters,
            openModalFilters,
            id,
        }),
        available: StatusColumn({ t, available, onGoing: ongoing, expired }),

        rowConfig: {
            actions: [
                {
                    id: "edit-name",
                    label: t("edit-name"),
                    onClick: () => {
                        openModalChangeName({
                            name,
                            id,
                            changeNameAction: updateTickerTape,
                            type: TICKER_TAPE_TYPE,
                        });
                    },
                },
                {
                    id: "edit-ticker-tape",
                    label: t("edit-ticker-tape"),
                    onClick: () => {
                        navigate(`/design/advertising/tickertapes/details/${id}`);
                    },
                },
                ...(available
                    ? [
                          {
                              id: "set-as-unavailable",
                              label: t("set-as-unavailable"),
                              onClick: () => {
                                  openModalChangeAvailabity({
                                      type: TICKER_TAPE_TYPE,
                                      name,
                                      available,
                                      changeAvailability: setAsUnavailable,
                                      id,
                                  });
                              },
                          },
                      ]
                    : [
                          {
                              id: "set-as-available",
                              label: t("set-as-available"),
                              disabled: !couldBeAvailable,
                              tooltip: !couldBeAvailable ? t("the-ticker-tape-will-be-able-to-set-as-available") : null,
                              onClick: () => {
                                  openModalChangeAvailabity({
                                      type: TICKER_TAPE_TYPE,
                                      name,
                                      available,
                                      changeAvailability: setAsAvailable,
                                      id,
                                  });
                              },
                          },
                      ]),

                {
                    id: "duplicate",
                    label: t("duplicate"),
                    onClick: () => {
                        openModalDuplicateTickerTape({
                            name,
                            id,
                            duplicateAction: duplicateTickerTape,
                            loading: loadingDuplicateTickerTape,
                            type: TICKER_TAPE_TYPE,
                        });
                    },
                },
                {
                    id: "delete",
                    label: t("delete"),
                    onClick: () => {
                        openModalDeleteTickerTape({
                            name,
                            id,
                            type: TICKER_TAPE_TYPE,
                            deleteAction: deleteTickerTapes,
                            onGoing: ongoing,
                        });
                    },
                },
            ],
        },
    };
};

const TickerTapes = () => {
    const { t } = useTranslation();
    const { setLoading } = useLoading();
    const navigate = useNavigate();
    const { getTickerTapes: listTickerTapes, tickerTapes, loading } = useListTickerTapes();
    const { open: openModalAddTickerTape } = UseModalAddTickerTape();
    const { open: openModalDeleteTickerTape, close: closeModalDeleteTickerTape } = UseModalDelete();
    const { open: openModalChangeAvailabity, close: closeModalChangeAvailability } = UseModalChangeAvailability();
    const { open: openModalDuplicateTickerTape, close: closeModalDuplicateTickerTape } = UseModalDuplicate();
    const { open: openModalChangeName } = UseModalChangeName();
    const [customSort, setCustomSort] = useState({ field: "id", criteria: "desc" });
    const [stateFilter, setStateFilter] = useState({
        available: null,
        onlyExpired: null,
        onlyOnGoing: null,
    });
    const [customSearch, setCustomSearch] = useState(null);
    const [customDateRangeFilter, setCustomDateRangeFilter] = useState({ from: null, to: null });
    const [batchActions, setBatchActions] = useState(INITIAL_BATCH_ACTIONS({ t }));

    function getTickerTapes() {
        const date = getCurrentTime();

        listTickerTapes({
            variables: {
                currentTime: date,
                orderBy: customSort,
                state: stateFilter?.state,
                available: stateFilter?.available,
                onlyOnGoing: stateFilter?.onlyOnGoing,
                onlyExpired: stateFilter?.onlyExpired,
                search: customSearch,
                scheduleFrom: customDateRangeFilter.from ? customDateRangeFilter.from.split("T")?.[0] : null,
                scheduleTo: customDateRangeFilter.to ? customDateRangeFilter.to.split("T")?.[0] : null,
            },
        });
    }

    useEffect(() => {
        getTickerTapes();
    }, []);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    useEffect(() => {
        if (!customSort) {
            setCustomSort({ field: "id", criteria: "desc" });
        }
        getTickerTapes();
    }, [customSort, stateFilter, customSearch, customDateRangeFilter]);

    const { deleteTickerTapes } = useDeleteTickerTapes({
        onCompleted: () => {
            getTickerTapes();
            closeModalDeleteTickerTape();
        },
    });

    const { updateTickerTape } = useUpdateTickerTapes({
        onCompleted: () => {
            getTickerTapes();
            closeModalDeleteTickerTape();
        },
    });

    const { setAsAvailable, setAsUnavailable } = useSetAvailabilityTickerTape({
        onCompleted: () => {
            getTickerTapes();
            closeModalChangeAvailability();
        },
    });

    const { duplicateTickerTape, loading: loadingDuplicateTickerTape } = useDuplicateTickerTape({
        onCompleted: () => {
            getTickerTapes();
            closeModalDuplicateTickerTape();
        },
    });

    return (
        <>
            <UseSectionHeader title={["ticker-tapes"]} value={"demo"} />
            <Table
                id={"ticker-tapes-table"}
                cols={COLUMNS_TICKER_TAPES}
                search={true}
                paginate={true}
                showCount={true}
                perPage={8}
                className={"main-container"}
                batchActions={{
                    options: Object.values(batchActions),
                    onChange: (action, rows) => {
                        if (action == batchActions?.delete?.value) {
                            openModalDeleteTickerTape({
                                deleteAction: deleteTickerTapes,
                                type: TICKER_TAPE_TYPE,
                                batch: true,
                                onGoing: rows.map((val) => val.ongoing).includes(true),
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                            });
                        }
                        if (action == batchActions?.available?.value) {
                            openModalChangeAvailabity({
                                batch: true,
                                available: false,
                                type: TICKER_TAPE_TYPE,
                                changeAvailability: setAsAvailable,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                            });
                        }
                        if (action == batchActions?.unavailable?.value) {
                            openModalChangeAvailabity({
                                batch: true,
                                changeAvailability: setAsUnavailable,
                                type: TICKER_TAPE_TYPE,
                                available: true,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                            });
                        }
                        if (action == batchActions?.duplicate?.value) {
                            openModalDuplicateTickerTape({
                                duplicateAction: duplicateTickerTape,
                                loading: loadingDuplicateTickerTape,
                                type: TICKER_TAPE_TYPE,
                                batch: true,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                            });
                        }
                    },
                }}
                topRightCorner={
                    <Button
                        design={"blue"}
                        id={"add-ticker-tape-button"}
                        onClick={() => {
                            openModalAddTickerTape({ getTickerTapes, navigate });
                        }}
                    >
                        {t("add-ticker-tape")}
                    </Button>
                }
                header={HEADERS({ t })}
                rows={
                    tickerTapes?.map((val) => {
                        return MapRows({
                            t,
                            data: val,
                            loadingDuplicateTickerTape,
                            modals: {
                                openModalDeleteTickerTape,
                                openModalChangeName,
                                openModalChangeAvailabity,
                                openModalDuplicateTickerTape,
                            },
                            setAsAvailable,
                            setAsUnavailable,
                            getTickerTapes,
                            deleteTickerTapes,
                            updateTickerTape,
                            duplicateTickerTape,
                            navigate,
                        });
                    }) || []
                }
                filters={[
                    {
                        id: "filter-by-state",
                        title: t("state"),
                        multiple: true,
                        options: stateFilterOptions({ t }),
                        onChange: (val) => {
                            const filters = {
                                available: null,
                                onlyExpired: val?.includes(FILTER_VALUES.ONLY_EXPIRED) || null,
                                onlyOnGoing: val?.includes(FILTER_VALUES.ONLY_ON_GOING) || null,
                            };

                            const includesAvailable =
                                val?.includes(FILTER_VALUES.AVAILABLE) ||
                                val?.includes(FILTER_VALUES.ONLY_ON_GOING) ||
                                val?.includes(FILTER_VALUES.ONLY_EXPIRED);
                            const includesUnavailable = val?.includes(FILTER_VALUES.UNAVAILABLE);

                            if (includesAvailable || includesUnavailable) {
                                filters.available = [];
                                if (includesAvailable) filters.available.push(true);
                                if (includesUnavailable) filters.available.push(false);
                            }

                            setStateFilter(filters);
                        },
                    },
                ]}
                onChangeSelectedRows={(rows) => {
                    const disabledFilters = rows?.reduce(
                        (acc, curr) => {
                            if (Object.entries(JSON.parse(curr?.data?.content || "{}"))?.length == 0) {
                                acc.hasNotContent = true;
                            }
                            if (curr?.data?.filtersNum == 0) {
                                acc.hasNotFilters = true;
                            }
                            return acc;
                        },
                        { hasNotContent: false, hasNotFilters: false }
                    );
                    setBatchActions((val) => {
                        return {
                            duplicate: { ...val.duplicate },
                            available: {
                                ...val.available,
                                disabled: disabledFilters?.hasNotContent || disabledFilters?.hasNotFilters,
                            },
                            unavailable: {
                                ...val.unavailable,
                            },
                            delete: { ...val.delete },
                        };
                    });
                }}
                customSort={{
                    value: customSort,
                    onChange: setCustomSort,
                }}
                customRangeSelector={{
                    value: customDateRangeFilter,
                    onChange: setCustomDateRangeFilter,
                    hideTimePicker: true,
                }}
                customSearch={{
                    value: customSearch,
                    onChange: setCustomSearch,
                }}
            ></Table>
        </>
    );
};

export default TickerTapes;
