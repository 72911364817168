import React, { useEffect, useState } from "react";
import { TickerTapesContent } from "./TickerTapesContent";
import UseSectionHeader from "components/useSectionHeader";
import Switch from "components/Switch";
import { useListTickerTapes, useSetAvailabilityTickerTape, useTheme } from "../graphql/useTickerTapes";
import AddFiltersCard from "../AddFiltersCard";
import ScheduleCard from "../ScheduleCard";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TICKER_TAPE_TYPE } from "../constants";
import { UseModalChangeAvailability } from "../modals/useModalChangeAvailability";
import useModalScheduleTickerTapeBanner from "../modals/signages/useModalScheduleTickerTapeBanner";
import { toast } from "react-toastify";
import TickerTapeDisplayMode from "./TickerTapeDisplayMode";

function hasValidContent(tickerTape) {
    const contentObj = JSON.parse(tickerTape?.content ?? "{}");
    return Object.entries(contentObj).length > 0;
}

function canBeAvailable(tickerTape) {
    return hasValidContent(tickerTape) && tickerTape?.filtersNum > 0 && tickerTape?.scheduleStart;
}

const TickerTapeDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const { getTickerTapes, tickerTapes, loading, refetchTickerTapes, idDesignApplied } = useListTickerTapes();
    const { getTheme, theme } = useTheme();
    const { setAsAvailable, setAsUnavailable } = useSetAvailabilityTickerTape({
        onCompleted: () => {
            fetchTickerTape();
            closeModalChangeAvailability();
        },
    });

    const { open: openModalChangeAvailability, close: closeModalChangeAvailability } = UseModalChangeAvailability();
    const { open: openModalScheduleTickerTapeBanner } = useModalScheduleTickerTapeBanner();

    const [isAvailable, setIsAvailable] = useState(false);

    const fetchTickerTape = () => {
        getTickerTapes({ variables: { id } });
    };

    useEffect(() => {
        fetchTickerTape();
    }, [id]);

    useEffect(() => {
        if (idDesignApplied) {
            getTheme({
                variables: {
                    filter: { designID: idDesignApplied },
                },
            });
        }
    }, [idDesignApplied, getTheme]);

    const tickerTape = tickerTapes?.[0];

    useEffect(() => {
        if (!tickerTape) return;

        const noContentOrNoFilters = !hasValidContent(tickerTape) || tickerTape.filtersNum === 0;

        if (noContentOrNoFilters && tickerTape.available) {
            setAsUnavailable({ variables: { ids: [id] } });
        }
    }, [tickerTape, id, setAsUnavailable]);

    const couldBeAvailable = canBeAvailable(tickerTape);

    const {
        scheduleDaysOfWeek,
        scheduleDaysType,
        scheduleStart,
        scheduleEnd,
        scheduleFrequencyDisplay,
        scheduleFrequencyLimited,
        timeRanges,
        scheduleObtrusiveMode,
        name,
        scheduleFrequencyRepetitions,
        displayScrollSpeed,
        displayTextSize,
        displayStyles,
    } = tickerTape || {};

    const handleAvailabilityChange = (val) => {
        openModalChangeAvailability({
            name,
            available: isAvailable,
            type: TICKER_TAPE_TYPE,
            id,
            changeAvailability: isAvailable ? setAsUnavailable : setAsAvailable,
            onCancel: () => {
                setIsAvailable(tickerTape?.available);
            },
        });
        setIsAvailable(val.checked);
    };

    const handleScheduleModal = () => {
        openModalScheduleTickerTapeBanner({
            onCompleted: () => {
                refetchTickerTapes();
                toast.success(t("operation-successful"));
            },
            data: {
                id,
                type: TICKER_TAPE_TYPE,
                modalTitle: "schedule-ticker-tape",
                scheduleDaysOfWeek,
                scheduleDaysType,
                scheduleStart,
                scheduleEnd,
                scheduleFrequencyDisplay,
                scheduleFrequencyLimited,
                timeRanges,
                scheduleObtrusiveMode,
                scheduleFrequencyRepetitions,
            },
        });
    };

    return (
        <div>
            <div className="flex justify-between items-end">
                <UseSectionHeader
                    title={name}
                    buttonColor="btn-blue"
                    action="function"
                    value="demo"
                    navToSection="/design/advertising/tickertapes"
                    breadcrumbsLimit={3}
                />

                <div className="mb-8 flex gap-4">
                    <Switch
                        onChange={handleAvailabilityChange}
                        tooltip={
                            !couldBeAvailable && !isAvailable
                                ? t("the-ticker-tape-will-be-able-to-set-as-available")
                                : null
                        }
                        disabled={!isAvailable && !couldBeAvailable}
                        checked={isAvailable}
                        label={t("available")}
                        className="flex flex-row-reverse gap-2 font-bold"
                        value={isAvailable}
                    />
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <ScheduleCard
                    data={tickerTapes}
                    loading={loading}
                    type={TICKER_TAPE_TYPE}
                    title="schedule-ticker-tape"
                    tooltip="the-default-sequence-cannot-be-programmed"
                    subtitle="set-up-the-ticker-tape-schedule-to-be-displayed-in-the-tv-interface"
                    display="ticker-tape-display"
                    openModal={handleScheduleModal}
                />
                <AddFiltersCard subtitle="set-up-the-filters-ticker-tape" />

                <TickerTapesContent getData={fetchTickerTape} translations={tickerTape?.content} loading={loading} />
                <TickerTapeDisplayMode
                    displayScrollSpeed={displayScrollSpeed}
                    displayTextSize={displayTextSize}
                    displayStyles={displayStyles}
                    theme={theme}
                />
            </div>
        </div>
    );
};

export default TickerTapeDetails;
