import { useEffect, useState } from "react";
import { GET_THEME, GET_TICKER_TAPES } from "./queries/tickerTapes";
import {
    ADD_TICKER_TAPE,
    DELETE_TICKER_TAPES,
    DUPLICATE_TICKER_TAPE,
    SET_TICKER_TAPE_AS_AVAILABLE,
    SET_TICKER_TAPE_AS_UNAVAILABLE,
    UPDATE_TICKER_TAPE,
    UPDATE_SCHEDULE_TICKER_TAPE,
    UPDATE_DISPLAY_TICKER_TAPE,
} from "./mutations/tickertapes";
import { useLazyQuery, useMutation } from "react-apollo";

export const useListTickerTapes = () => {
    const [tickerTapes, setTickerTapes] = useState([]);
    const [idDesignApplied, setIdDesignApplied] = useState(null);

    const [listTickerTapes, { loading, error, refetch, data }] = useLazyQuery(GET_TICKER_TAPES, {
        nextFetchPolicy: "cache-first",
        onCompleted: (data) => {
            setTickerTapes(data.tickerTapes.results);
        },
    });

    useEffect(() => {
        if (!data) return;
        setTickerTapes(data.tickerTapes?.results || []);
        const designsAssigned = data.designsLocalAssigned?.results;
        const corporateDesigns = data.designs?.results?.filter((design) => design.isAssignedFromCorporate);
        setIdDesignApplied(designsAssigned?.[0]?.id || corporateDesigns?.[0]?.id || null);
    }, [data]);

    return {
        getTickerTapes: listTickerTapes,
        loading: loading,
        error: error,
        tickerTapes: tickerTapes,
        idDesignApplied: idDesignApplied,
        refetchTickerTapes: refetch,
    };
};

export const useTheme = () => {
    const [theme, setTheme] = useState(null);

    const [getTheme, { loading, error, refetch, data }] = useLazyQuery(GET_THEME, {
        nextFetchPolicy: "cache-first",
        onCompleted: (data) => {
            if (data?.themes?.results?.length > 0) {
                const colors = data.themes.results[0].config.colors;
                setTheme(colors);
            }
        },
    });

    return {
        getTheme,
        theme,
    };
};

export const useAddTickerTape = (props) => {
    const [executeMutation] = useMutation(ADD_TICKER_TAPE, {
        onCompleted({ createTickerTape }) {
            props.onCompleted({ id: createTickerTape?.id });
        },
    });

    return {
        addTickerTape: executeMutation,
    };
};

export const useDeleteTickerTapes = (props) => {
    const [executeMutation] = useMutation(DELETE_TICKER_TAPES, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        deleteTickerTapes: executeMutation,
    };
};

export const useUpdateTickerTapes = (props) => {
    const [executeMutation] = useMutation(UPDATE_TICKER_TAPE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        updateTickerTape: executeMutation,
    };
};

export const useSetAvailabilityTickerTape = (props) => {
    const [setAsUnavailable] = useMutation(SET_TICKER_TAPE_AS_UNAVAILABLE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    const [setAsAvailable] = useMutation(SET_TICKER_TAPE_AS_AVAILABLE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        setAsAvailable,
        setAsUnavailable,
    };
};

export const useDuplicateTickerTape = (props) => {
    const [executeMutation, { loading }] = useMutation(DUPLICATE_TICKER_TAPE, {
        onCompleted() {
            props.onCompleted();
        },
    });
    return {
        duplicateTickerTape: executeMutation,
        loading,
    };
};

export const useUpdateScheduleTickerTape = (props) => {
    const [executeMutation, { loading }] = useMutation(UPDATE_SCHEDULE_TICKER_TAPE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        updateScheduleTickerTape: executeMutation,
        loadingScheduleTickerTape: loading,
    };
};

export const useUpdateDisplayTickerTape = (props) => {
    const [executeMutation, { loading }] = useMutation(UPDATE_DISPLAY_TICKER_TAPE, {
        onCompleted() {
            if (props?.onCompleted) {
                props.onCompleted();
            }
        },
    });

    return {
        updateDisplayTickerTape: executeMutation,
        loadingScheduleTickerTape: loading,
    };
};
